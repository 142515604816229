<template>
  <b-button
    v-on="$listeners"
    :class="[$style.baseBtn, 'dflex_nowrap', { 'deleteBtn': isNotMainColor }]"
    type="submit"
  >
    <div :class="$style.baseBtnIcon">
      <slot name="icon"></slot>
    </div>
    <div>
      <slot name="text">
        Запросить
      </slot>
    </div>
    <div :class="$style.baseBtnIconRight">
      <slot name="iconRight"></slot>
    </div>
  </b-button>
</template>

<script>
export default {
  props: {
    isNotMainColor: {
      type: Boolean,
      require: false,
      default: false
    }
  }
};
</script>

<style lang="scss" module>
.baseBtn {
  min-width: 6.5rem;
  color: $white;
  font-size: 0.875rem;
  background: $red;
  border-color: $red;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;

  .baseBtnIcon {
    margin: 0;
    padding-right: 0.25rem;
  }

  .baseBtnIconRight {
    margin: 0;
    padding-left: 0.25rem;
  }
}

.baseBtn:focus,
.baseBtn:hover,
.baseBtn:not(:disabled):not(.disabled):active {
  color: $white;
  background: $hoverButton;
  border-color: $hoverButtonBorder;
}

.deleteBtn {
  color: $white;
  background: $mainGrey;
  border: 1px solid $mainGrey;
}

// @media (min-width: 576px){
//   .baseBtn{
//     font-size: 1rem;
//   }
// }
</style>
