<template>
  <div>
    <nav id="nav">
      <ul class="dflex reg-pagination">
        <li class="dflex">
          <base-back-button :disabled="this.currentPage === 1" @clickBack="showPrevious">
            <template #text>Показать предыдущие</template>
          </base-back-button>
        </li>
        <li class="dflex">
          <BaseButton @click="showNext">
            <template #text>Показать следующие</template>
            <template #iconRight>
              <CIcon name="cilArrowCircleRight" alt="Вперед"></CIcon>
            </template>
          </BaseButton>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import BaseBackButton from '@/components/shared/buttons/BaseBackButton.vue';

export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    }
  },

  components: {
    BaseButton,
    BaseBackButton
  },

  methods: {
    showPrevious() {
      this.$emit('previous-data');
    },

    showNext() {
      this.$emit('next-data');
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
}

@media (min-width: 576px) {
  li:first-child {
    padding-right: 1rem;
  }

  li:last-child {
    padding-left: 1rem;
  }
}

@media (max-width: 575px) {
  div{
    width: 100%
  }
  ul {
    justify-content: space-between;
  }
  button {
    font-size: 0.75rem;
    max-width: 8.5rem;
  }
}
</style>
